import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  ticketId: yup.number().required('Carrier is a required field'),
  destinationId: yup.number().required('Destination is a required field'),
  invoiceDate: yup.date().required('Invoice date is a required field'),
  invoiceNumber: yup.string().required('Invoice number is a required field'),
  invoiceAmount: yup
    .number()
    .moreThan(0, 'Tare Weight must be a positive number')
    .required('Invoice amount is a required field'),
  grossWeight: yup
    .number()
    .required('Gross weight is a required field')
    .moreThan(yup.ref('tareWeight'), 'Gross Weight must be greater than Tare Weight'),
  tareWeight: yup
    .number()
    .required('Tare weight is a required field')
    .moreThan(0, 'Tare Weight must be a positive number'),
  carEquipment: yup
    .string()
    .required('Car/Equipment is a required field')
    .matches(/^[A-Za-z0-9]{6,}$/, 'Car/Equipment must contain alphanumeric digits (min 6)')
    .test(
      'not-equal-to-invoiceNumber', // Test name
      'Car/Equipment and Invoice Number must be different', // Error message
      function notEqualToInvoiceNumber(value) {
        const { invoiceNumber } = this.parent
        return invoiceNumber !== value
      },
    ),
  materialId: yup.string().required('Material is a required field'),
  materialDescription: yup.string().nullable().optional(),
  purchaseOrderId: yup.string().nullable().optional(),
  salesInvoice: yup.mixed().required('Invoice is a required file'),
  weightCertificate: yup.mixed().required('Weight cert. is a required file'),
  BL: yup.mixed().required('B/L is a required file'),
  radioactiveCertificate: yup.mixed().required('Radioactive cert. is a required file'),
  photos: yup.mixed().required('This is a required file'),
})

export const validationUpdateSchema = yup.object().shape({
  destinationId: yup.number().required('Destination is required'),
  invoiceDate: yup.date().optional(),
  invoiceNumber: yup.string().optional(),
  invoiceAmount: yup
    .number()
    .moreThan(0, 'Tare Weight must be a positive number')
    .required('Invoice amount is a required field'),
  grossWeight: yup
    .number()
    .required('Gross weight is a required field')
    .moreThan(yup.ref('tareWeight'), 'Gross Weight must be greater than Tare Weight'),
  tareWeight: yup
    .number()
    .required('Tare weight is a required field')
    .moreThan(0, 'Tare Weight must be a positive number'),
  carEquipment: yup
    .string()
    .required('Car/Equipment is a required field')
    .matches(/^[A-Za-z0-9]{6,}$/, 'Car/Equipment must contain alphanumeric digits (min 6)')
    .test(
      'not-equal-to-invoiceNumber', // Test name
      'Car/Equipment and Invoice Number must be different', // Error message
      function notEqualToInvoiceNumber(value) {
        const { invoiceNumber } = this.parent
        return invoiceNumber !== value
      },
    ),
  materialId: yup.string().optional(),
  purchaseOrderId: yup.string().nullable().optional(),
  salesInvoice: yup.mixed().optional(),
  weightCertificate: yup.mixed().optional(),
  BL: yup.mixed().optional(),
  radioactiveCertificate: yup.mixed().optional(),
})

export interface Form {
  ticketId: number
  destinationId: number
  invoiceDate: Date
  invoiceNumber: string
  invoiceAmount: number
  grossWeight: number
  tareWeight: number
  carEquipment?: string
  materialId: string
  materialDescription: string
  purchaseOrderId?: string
  salesInvoice: Blob
  weightCertificate: Blob
  BL: Blob
  radioactiveCertificate: Blob
  photos: Blob[]
}

export interface UpdateForm {
  destinationId: number
  invoiceDate: Date
  invoiceNumber: string
  invoiceAmount?: number
  grossWeight?: number
  tareWeight?: number
  carEquipment?: string
  materialId?: string
  materialDescription?: string
  purchaseOrderId?: string
  salesInvoice?: Blob
  weightCertificate?: Blob
  BL?: Blob
  radioactiveCertificate?: Blob
  photos: Blob[]
  truckDocumentationId: number
}
