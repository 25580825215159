import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { HomeIcon } from '@icons'
import { Filters } from '@types'
import { useFilters } from '@contexts'

import { StyledMenu } from './components'

const SupplierMenu: () => JSX.Element = () => {
  const { pathname } = useLocation()
  const { url } = useRouteMatch()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { setFilters } = useFilters()

  const handleCleanFilters = () => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierId: [],
      carrierMode: [],
      statusName: [],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const onHomeClick = () => {
    push('/dashboard/customs')
    handleCleanFilters()
  }

  return (
    <StyledMenu selectedKeys={[pathname]}>
      <StyledMenu.Item key={`${url}/customs`} icon={<HomeIcon />} onClick={() => onHomeClick()}>
        {t('customs_router.menu.requests')}
      </StyledMenu.Item>
    </StyledMenu>
  )
}

export { SupplierMenu }
