import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  CategoriesIcon,
  DepotIcon,
  HomeIcon,
  ContractIcon,
  SettingsIcon,
  AccountingIcon,
  SupplierIcon,
} from '@icons'
import { useFilters } from '@contexts'
import { Filters } from '@types'

import { StyledMenu } from './components'

const { SubMenu } = StyledMenu

const AdminMenu: () => JSX.Element = () => {
  const { url } = useRouteMatch()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { setFilters } = useFilters()

  const handleCleanFilters = () => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierId: [],
      carrierMode: [],
      statusName: [],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const onOngoingClick = () => {
    push('/dashboard/admin/ongoing')
    handleCleanFilters()
  }

  const onDeliveredClick = () => {
    push('/dashboard/admin/delivered')
    handleCleanFilters()
  }

  return (
    <StyledMenu
      mode="inline"
      defaultSelectedKeys={[`${url}/ongoing`]}
      defaultOpenKeys={['submen1']}
    >
      <SubMenu key="submen1" icon={<HomeIcon />} title={t('admin_router.menu.requests')}>
        <StyledMenu.Item key={`${url}/ongoing`} onClick={() => onOngoingClick()}>
          {t('admin_router.menu.ongoing')}
        </StyledMenu.Item>
        <StyledMenu.Item key={`${url}/delivered`} onClick={() => onDeliveredClick()}>
          {t('admin_router.filter_options.status.delivered')}
        </StyledMenu.Item>
      </SubMenu>
      <StyledMenu.Item
        key={`${url}/accounting`}
        icon={<AccountingIcon width={18} height={15} />}
        onClick={() => push('/dashboard/admin/accounting')}
      >
        {t('admin_router.menu.accounting')}
      </StyledMenu.Item>
      <StyledMenu.Item
        key={`${url}/service-level`}
        icon={<HomeIcon />}
        onClick={() => push('/dashboard/admin/service-level')}
      >
        {t('admin_router.menu.service_level')}
      </StyledMenu.Item>
      <SubMenu
        key={`${url}/settings`}
        icon={<SettingsIcon width={18} height={15} />}
        title={t('admin_router.menu.settings')}
      >
        <StyledMenu.Item
          key={`${url}/general`}
          icon={<SettingsIcon width={18} height={15} />}
          onClick={() => push('/dashboard/admin/settings')}
        >
          {t('admin_router.menu.general')}
        </StyledMenu.Item>
        <StyledMenu.Item
          key={`${url}/contracts`}
          icon={<ContractIcon width={18} height={15} />}
          onClick={() => push('/dashboard/admin/contracts')}
        >
          {t('admin_router.menu.contracts')}
        </StyledMenu.Item>
        <StyledMenu.Item
          key={`${url}/categories`}
          icon={<CategoriesIcon height={15} width={18} />}
          onClick={() => push('/dashboard/admin/categories')}
        >
          {t('admin_router.menu.categories')}
        </StyledMenu.Item>
        <StyledMenu.Item
          key={`${url}/suppliersabm`}
          icon={<SupplierIcon height={15} width={18} />}
          onClick={() => push('/dashboard/admin/suppliersabm')}
        >
          {t('admin_router.menu.suppliers')}
        </StyledMenu.Item>
        <StyledMenu.Item
          key={`${url}/depotsabm`}
          icon={<DepotIcon height={15} width={18} />}
          onClick={() => push('/dashboard/admin/depotsabm')}
        >
          {t('admin_router.menu.depots')}
        </StyledMenu.Item>
      </SubMenu>
    </StyledMenu>
  )
}

export { AdminMenu }
