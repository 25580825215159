import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { RailcarDocumentationApi } from './api'

const useUpdateRailcarDocumentation = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const onError = () => {
    notification.error({
      message: 'Railcar update error',
    })

    history.push('/dashboard/suppliers')
  }
  const { mutate, isLoading } = useMutation(RailcarDocumentationApi.updateRailcarDocumentation, {
    onSuccess: data => {
      notification.success({
        message: `Railcar ID: ${data.railcarDocumentation.id} ${t(
          'ta_invoice.add_truck_documentation.title_and_divider.new_railcar.update',
        )}`,
      })
      history.push('/dashboard/suppliers')
    },
    onError,
  })

  return {
    updateRailcarDocumentation: mutate,
    isLoading,
  }
}

export { useUpdateRailcarDocumentation }
