import { useContext, useEffect, useState } from 'react'
import { Row, Col, Table, Pagination, RadioChangeEvent, Button } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { SelectValue } from 'antd/lib/select'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { valueType } from 'antd/lib/statistic/utils'

import { useCarriers, useDestinations, usePagination, useTickets } from '@hooks'
import { theme } from '@styles'
import { FilterIcon, EyeIcon, DollarIcon, RailcarIcon, TruckIcon } from '@icons'
import { Filters, StatusTicketEnum, Ticket, CarrierModeEnum } from '@types'
import { useFilters } from '@contexts'

import {
  DeleteTicketNotificationContext,
  useHandleNewTicketModal,
  useHandleDeleteTicketModal,
} from './contexts'
import {
  Container,
  StyledButton,
  StyledSelect,
  NewTicketModal,
  MobileFilters,
  TicketCard,
  DeleteTicketModal,
  StyledCol,
} from './components'
import { useCreateTicket, useDeleteTicket } from './hooks'
import { Form, DeleteTicketForm } from '../validations'

const SuppliersDashboard = () => {
  const [isFiltersMobileOpen, setIsTicketsMobileOpen] = useState(false)

  const { t } = useTranslation()
  const { xs, sm, md } = useBreakpoint()
  const { createTicket, isLoading: isCreateTicketLoading } = useCreateTicket()
  const { deleteTicket, isLoading: isDeleteTicketLoading } = useDeleteTicket()
  const { isNewTicketModalOpen, setIsTicketModalOpen } = useHandleNewTicketModal()
  const { isDeleteTicketModalOpen, setIsDeleteTicketModalOpen } = useHandleDeleteTicketModal()
  const { setTicketDeleteId } = useContext(DeleteTicketNotificationContext)
  const { filters, setFilters } = useFilters()
  const { tickets, isLoading } = useTickets(filters)
  const { destinationsOptions } = useDestinations()
  const pagination = usePagination({ filters, setFilters }, tickets?.total)
  const { carriersOptions, isLoading: carriersLoading } = useCarriers()

  const dateFormat = 'll'
  const dateHourFormat = 'MMM DD YYYY, h:mm a'

  const isTicketPendingOrConfirmed = (ticket: Ticket) =>
    ticket.status.name === StatusTicketEnum.PENDING ||
    ticket.status.name === StatusTicketEnum.CONFIRMED

  const getStatusName = (name: string) => {
    const statusName =
      name === StatusTicketEnum.SUPPLIER_INVOICED ||
      name === StatusTicketEnum.TA_INVOICED ||
      name === StatusTicketEnum.MX_TRANSIT ||
      name === StatusTicketEnum.DELIVERED
        ? StatusTicketEnum.INVOICED
        : name

    return statusName
  }

  const getStateByName = (names: string[]) => {
    return names.filter(name => name === StatusTicketEnum.INVOICED).length
      ? [
          ...names,
          StatusTicketEnum.SUPPLIER_INVOICED,
          StatusTicketEnum.TA_INVOICED,
          StatusTicketEnum.MX_TRANSIT,
          StatusTicketEnum.DELIVERED,
        ]
      : names
  }
  /*
  const getStateToSelect = (stateFilter: string[]) => {
    if (stateFilter.includes(StatusTicketEnum.INVOICED)) {
      const filtered = stateFilter.filter(
        state => state === StatusTicketEnum.PENDING || state === StatusTicketEnum.CONFIRMED,
      )
      return [...filtered, StatusTicketEnum.INVOICED]
    }
    return stateFilter
  }
  */

  const columns = [
    {
      title: t('suppliers_router.suppliers_dashboard.columns.trip_id'),
      render: (ticket: Ticket) => (
        <>
          {ticket.carrier && ticket.carrier.mode === CarrierModeEnum.RAILCAR ? (
            <RailcarIcon width={18} height={15} />
          ) : null}
          {ticket.ticketAssignments.length &&
          ticket.ticketAssignments[0].carrier.mode === CarrierModeEnum.TRUCK ? (
            <TruckIcon width={18} height={15} />
          ) : null}{' '}
          {ticket.id}
        </>
      ),
    },
    {
      title: t('suppliers_router.suppliers_dashboard.columns.order_date'),
      dataIndex: 'createAt',
      key: 'id',
      render: (date: Date) => moment(date).format(dateFormat),
    },
    {
      title: t('suppliers_router.suppliers_dashboard.columns.load_date'),
      render: (ticket: Ticket) =>
        ticket.truckDocumentation ? (
          <>{moment(ticket.truckDocumentation.createAt).format(dateFormat)}</>
        ) : (
          ticket.railcarDocumentation && (
            <>{moment(ticket.railcarDocumentation.createAt).format(dateFormat)}</>
          )
        ),
    },
    {
      title: t('suppliers_router.suppliers_dashboard.columns.invoice_number'),
      render: (ticket: Ticket) =>
        ticket.truckDocumentation ? (
          <>{ticket.truckDocumentation.invoiceNumber}</>
        ) : (
          ticket.railcarDocumentation && <>{ticket.railcarDocumentation.invoiceNumber}</>
        ),
    },
    {
      title: t('suppliers_router.suppliers_dashboard.columns.carrier'),
      dataIndex: 'carrier',
      key: 'id',
      render: (carrier?: { id: number; businessName: string }) =>
        carrier && <>{carrier.businessName}</>,
    },
    {
      title: t('suppliers_router.suppliers_dashboard.columns.destination'),
      dataIndex: 'destination',
      key: 'destination',
      render: (destination: { name: string }) => <>{destination ? destination.name : '-'}</>,
    },
    {
      title: t('suppliers_router.suppliers_dashboard.columns.withdrawal_date'),
      dataIndex: 'withdrawalDate',
      key: 'withdrawalDate',
      render: (withdrawalDate: Date) => (
        <>{withdrawalDate ? moment(withdrawalDate).format(dateHourFormat) : '-'}</>
      ),
    },
    {
      title: t('suppliers_router.suppliers_dashboard.columns.status'),
      dataIndex: 'status',
      key: 'id',
      // TODO: Translate status name
      render: ({ name }: { name: string }) => {
        const status = getStatusName(name).toLowerCase()

        const style =
          status === StatusTicketEnum.CANCELED.toLowerCase() ? { color: 'red' } : undefined

        return (
          <>
            <span style={style}>{t(`suppliers_router.suppliers_dashboard.status.${status}`)}</span>
          </>
        )
      },
    },
    {
      title: t('suppliers_router.suppliers_dashboard.columns.actions'),

      render: (ticket: Ticket) => {
        return (
          <>
            {!isTicketPendingOrConfirmed(ticket) && (
              <Link
                to={{
                  pathname: `/dashboard/suppliers/${
                    ticket.carrier.mode === CarrierModeEnum.RAILCAR ? 'railcar' : 'truck'
                  }-documentation`,
                  state: { ticket },
                }}
              >
                <EyeIcon />
              </Link>
            )}
            {ticket.invoice?.paymentDate && <DollarIcon height={21} fill="green" />}
          </>
        )
      },
    },
  ]
  const filterOptions = [
    {
      label: `${t('suppliers_router.filter_options.status.pending')}`,
      value: StatusTicketEnum.PENDING,
    },
    {
      label: `${t('suppliers_router.filter_options.status.confirmed')}`,
      value: StatusTicketEnum.CONFIRMED,
    },
    {
      label: `${t('suppliers_router.filter_options.status.invoiced')}`,
      value: StatusTicketEnum.INVOICED,
    },

    // NUEVOS

    {
      label: `${t('suppliers_router.filter_options.status.transit')}`,
      value: StatusTicketEnum.TRANSIT,
    },
    {
      label: `${t('suppliers_router.filter_options.status.delivered')}`,
      value: StatusTicketEnum.DELIVERED,
    },
    {
      label: `${t('suppliers_router.filter_options.status.awaiting')}`,
      value: StatusTicketEnum.AWAITING,
    },
    {
      label: `${t('suppliers_router.filter_options.status.released')}`,
      value: StatusTicketEnum.RELEASED,
    },
    {
      label: `${t('suppliers_router.filter_options.status.supplier_invoiced')}`,
      value: StatusTicketEnum.SUPPLIER_INVOICED,
    },
    {
      label: `${t('suppliers_router.filter_options.status.ta_invoiced')}`,
      value: StatusTicketEnum.TA_INVOICED,
    },
    {
      label: `${t('suppliers_router.filter_options.status.mx_transit')}`,
      value: StatusTicketEnum.MX_TRANSIT,
    },
    {
      label: `${t('suppliers_router.filter_options.status.canceled')}`,
      value: StatusTicketEnum.CANCELED,
    },
  ]

  const buttons = [
    {
      name: t('suppliers_router.buttons.new_request'),
      primary: true,
      icon: PlusOutlined,
      action: () => setIsTicketModalOpen(true),
    },
    {
      name: t('suppliers_router.buttons.delete_request'),
      primary: false,
      icon: MinusOutlined,
      action: () => setIsDeleteTicketModalOpen(true),
      backgroundColor: theme.secondaryDarkGray,
    },
  ]

  const orderByOptions = [
    {
      label: `${t('suppliers_router.filter_options.order_by.ascending')}`,
      value: 'ASC',
    },
    {
      label: `${t('suppliers_router.filter_options.order_by.descending')}`,
      value: 'DESC',
    },
  ]

  const carrierModeOptions = [
    {
      label: (
        <>
          <RailcarIcon height={15} width={15} />{' '}
          {t('suppliers_router.filter_options.carrier.railcar')}
        </>
      ),
      value: CarrierModeEnum.RAILCAR,
    },
    {
      label: (
        <>
          <TruckIcon height={15} width={15} /> {t('suppliers_router.filter_options.carrier.truck')}
        </>
      ),
      value: CarrierModeEnum.TRUCK,
    },
  ]

  const handleTicketIdFilterChange = (ticketId: string) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      ticketId,
    }))
  }

  const submitHandler = (form: Form, reset: () => void) => {
    createTicket(form)
    reset()
  }

  const filterTicketsByStatus = (ticketsToFilter: Ticket[], status: StatusTicketEnum) => {
    return ticketsToFilter
      .filter(ticket => ticket.status.name === status)
      .sort((a, b) => {
        return b.id - a.id
      })
  }

  const reducedTicketsByDepot = (ticketsToReduced: Ticket[]) =>
    ticketsToReduced.reduce((acc: Ticket[], current) => {
      const result = acc.find(item => item.origin.id === current.origin.id)
      if (!result) {
        return acc.concat([current])
      }
      return acc
    }, [])

  const filterTickets = (ticketsToFilter?: Ticket[]): Ticket[] => {
    if (!ticketsToFilter) return []
    const pendingTickets = filterTicketsByStatus(ticketsToFilter, StatusTicketEnum.PENDING)
    const confirmedTickets = filterTicketsByStatus(ticketsToFilter, StatusTicketEnum.CONFIRMED)
    const filteredTickets = reducedTicketsByDepot(pendingTickets).length
      ? reducedTicketsByDepot(pendingTickets)
      : reducedTicketsByDepot(confirmedTickets)

    return filteredTickets
  }

  const submitDeleteTicketHandler = (form: DeleteTicketForm, reset: () => void) => {
    setTicketDeleteId(form.ticketId)
    deleteTicket(form.ticketId)
    reset()
  }

  const handleStatusFilterChange = (value: SelectValue | CheckboxValueType[]) => {
    const state = getStateByName(value as string[])

    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      statusName: [...state],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const handleInvoiceNumberFilterChange = (invoiceNumber: valueType) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      invoiceNumber: invoiceNumber as string,
    }))
  }

  const handleOrderByFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      orderBy: value as string,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const handleOrderByMobileFilterChange = (value: RadioChangeEvent) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      orderBy: value.target.value as string,
    }))
  }

  const handleDestinationFilterChange = (value: SelectValue | CheckboxValueType[]) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      destinationId: [...(value as number[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const handleCarrierModeFilterChange = (value: SelectValue | CheckboxValueType[]) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierMode: [...(value as CarrierModeEnum[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const handleCarrierFilterChange = (value: SelectValue | CheckboxValueType[]) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierId: [...(value as number[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const handleCleanFilters = () => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierId: [],
      carrierMode: [],
      statusName: [
        StatusTicketEnum.PENDING,
        StatusTicketEnum.CONFIRMED,
        StatusTicketEnum.INVOICED,
        StatusTicketEnum.TRANSIT,
        StatusTicketEnum.DELIVERED,
        StatusTicketEnum.AWAITING,
        StatusTicketEnum.RELEASED,
        StatusTicketEnum.SUPPLIER_INVOICED,
        StatusTicketEnum.TA_INVOICED,
        StatusTicketEnum.MX_TRANSIT,
      ],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  useEffect(() => {
    setFilters({
      page: 1,
      limit: 10,
      statusName: [
        StatusTicketEnum.PENDING,
        StatusTicketEnum.CONFIRMED,
        StatusTicketEnum.INVOICED,
        StatusTicketEnum.TRANSIT,
        StatusTicketEnum.DELIVERED,
        StatusTicketEnum.AWAITING,
        StatusTicketEnum.RELEASED,
        StatusTicketEnum.SUPPLIER_INVOICED,
        StatusTicketEnum.TA_INVOICED,
        StatusTicketEnum.MX_TRANSIT,
      ],
    })
  }, [setFilters])

  return (
    <>
      <Container>
        {xs || (sm && !md) ? (
          <>
            <Row>
              <Col span={2} offset={22}>
                <FilterIcon onClick={() => setIsTicketsMobileOpen(true)} />
              </Col>
            </Row>
            <TicketCard tickets={tickets?.tickets} />
            <Row>
              <Col flex={6} />
              <Col flex="auto">
                <Pagination
                  current={pagination.current}
                  total={pagination?.total}
                  onChange={pagination.onChange}
                  responsive
                />
              </Col>
              <Col flex={6} />
            </Row>
            <MobileFilters
              open={isFiltersMobileOpen}
              onClose={() => setIsTicketsMobileOpen(false)}
              onChangeTicketIdFilter={e => handleTicketIdFilterChange(e.target.value)}
              onChangeStatusFilter={handleStatusFilterChange}
              onChangeInvoiceNumberFilter={handleInvoiceNumberFilterChange}
              onChangeOrderByFilter={handleOrderByMobileFilterChange}
              onChangeDestinationFilter={handleDestinationFilterChange}
              onChangeModeFilter={handleCarrierModeFilterChange}
              filters={filters}
            />
          </>
        ) : (
          <>
            <Row gutter={[10, 10]} style={{ marginBottom: '10px' }}>
              {buttons.map(button => {
                return (
                  <Col key={button.name} span={5}>
                    <StyledButton
                      type={button.primary ? 'primary' : 'default'}
                      // block
                      onClick={button.action}
                      icon={<button.icon />}
                      backgroundColor={button.backgroundColor}
                    >
                      {button.name}
                    </StyledButton>
                  </Col>
                )
              })}
              <StyledCol>
                <Button onClick={handleCleanFilters}>Clean filters</Button>
              </StyledCol>
            </Row>
            <Row style={{ marginBottom: '10px' }} gutter={[10, 10]}>
              <Col span={6}>
                <StyledSelect
                  mode="multiple"
                  allowClear
                  placeholder={t('suppliers_router.filter_options.status.title')}
                  options={filterOptions}
                  onChange={handleStatusFilterChange}
                  value={filters?.statusName} // {filters?.statusName && getStateToSelect(filters.statusName)}
                  maxTagCount="responsive"
                />
              </Col>
              <Col span={5}>
                <StyledSelect
                  mode="multiple"
                  allowClear
                  placeholder={t('suppliers_router.filter_options.carrier.name')}
                  options={carriersOptions}
                  loading={carriersLoading}
                  onChange={handleCarrierFilterChange}
                  value={filters?.carrierId}
                  maxTagCount="responsive"
                />
              </Col>
              <Col span={5}>
                <StyledSelect
                  mode="multiple"
                  allowClear
                  placeholder={t('suppliers_router.filter_options.carrier.mode')}
                  options={carrierModeOptions}
                  onChange={handleCarrierModeFilterChange}
                  value={filters?.carrierMode}
                  maxTagCount="responsive"
                />
              </Col>
              <Col span={5}>
                <StyledSelect
                  mode="multiple"
                  allowClear
                  placeholder={t('suppliers_router.filter_options.destination.title')}
                  options={destinationsOptions}
                  onChange={handleDestinationFilterChange}
                  value={filters?.destinationId}
                  maxTagCount="responsive"
                />
              </Col>
              <Col span={4}>
                <StyledSelect
                  allowClear
                  placeholder={t('suppliers_router.filter_options.order_by.title')}
                  options={orderByOptions}
                  onChange={handleOrderByFilterChange}
                  value={filters?.orderBy}
                />
              </Col>
            </Row>
            <Table
              columns={columns}
              loading={isLoading}
              dataSource={tickets?.tickets}
              pagination={pagination}
              rowKey="id"
              scroll={{ y: 'calc(100vh - 380px)' }}
            />
          </>
        )}
      </Container>
      <NewTicketModal
        open={isNewTicketModalOpen}
        title={t('suppliers_router.new_ticket_modal.title')}
        onClose={() => setIsTicketModalOpen(false)}
        onOk={submitHandler}
        isLoading={isCreateTicketLoading}
      />
      <DeleteTicketModal
        open={isDeleteTicketModalOpen}
        title={t('suppliers_router.delete_ticket_modal.title')}
        onClose={() => setIsDeleteTicketModalOpen(false)}
        onOk={submitDeleteTicketHandler}
        tickets={filterTickets(tickets?.tickets)}
        isLoading={isDeleteTicketLoading}
      />
    </>
  )
}

export { SuppliersDashboard }
