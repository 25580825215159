import { notification } from 'antd'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { BorderCrossingsApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error al obtener los destinos',
  })
}

const useBorderCrossings = () => {
  const { data: allBorders, isLoading: allBordersLoading } = useQuery(
    [QUERY_KEYS.ALLBORDERS],
    BorderCrossingsApi.getAllBorderCrossings,
    { onError },
  )

  let allBordersOptions: { label: string; value: number }[] = []
  if (Array.isArray(allBorders)) {
    allBordersOptions = allBorders?.map(border => {
      return {
        label: `${border.description} (${border.customsAgent.tradeName})`,
        value: border.id,
      }
    })
  }

  const { data: userBorders, isLoading: userBordersLoading } = useQuery(
    [QUERY_KEYS.USERBORDERS],
    BorderCrossingsApi.getUserBorderCrossings,
    {
      onError,
    },
  )

  let userBordersOptions: { label: string; value: number }[] = []
  if (Array.isArray(userBorders)) {
    userBordersOptions = userBorders?.map(border => {
      return {
        label: border.description,
        value: border.id,
      }
    })
  }

  return {
    allBorders,
    allBordersLoading,
    allBordersOptions,
    userBorders,
    userBordersLoading,
    userBordersOptions,
  }
}

export { useBorderCrossings }
