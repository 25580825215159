import { Table, TablePaginationConfig } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CarrierModeEnum, StatusTicketEnum, Ticket } from '@types'
import { EyeIcon, RailcarIcon, TruckIcon, UndoIcon } from '@icons'
import { useTickets } from '@hooks'

import { IconContainer, StyledText } from './components'

interface Props {
  dataSource?: Ticket[]
  pagination?: false | TablePaginationConfig
  isLoading?: boolean
  showActions?: boolean
}

const CustomsTable: React.FC<Props> = ({
  dataSource,
  isLoading,
  pagination = false,
  showActions = true,
}) => {
  const { t } = useTranslation()
  const { changeTicket, changeTicketLoading } = useTickets()
  const dateFormat = 'MMM DD, hh:mm a'

  const getStatusName = (name: string) => {
    const statusName =
      name === StatusTicketEnum.TA_INVOICED ? StatusTicketEnum.AWAITING : StatusTicketEnum.RELEASED

    return statusName
  }

  const columns = [
    {
      title: t('customs_router.customs_dashboard.columns.trip_id'),
      render: (ticket: Ticket) => (
        <IconContainer>
          {ticket.carrier && ticket.carrier.mode === CarrierModeEnum.RAILCAR ? (
            <RailcarIcon width={18} height={15} fill={ticket.customsRead ? '#D9D9D9' : '#555555'} />
          ) : null}
          {ticket.ticketAssignments.length &&
          ticket.ticketAssignments[0].carrier.mode === CarrierModeEnum.TRUCK ? (
            <TruckIcon width={18} height={15} fill={ticket.customsRead ? '#D9D9D9' : '#555555'} />
          ) : null}{' '}
          {ticket.customsRead ? ticket.id : <StyledText>{ticket.id}</StyledText>}
        </IconContainer>
      ),
    },
    {
      title: t('customs_router.customs_dashboard.columns.border_crossing'),
      render: (ticket: Ticket) =>
        ticket.invoice?.borderCrossing && (
          <>
            {ticket.customsRead ? (
              ticket.invoice.borderCrossing.description
            ) : (
              <StyledText>{ticket.invoice.borderCrossing.description}</StyledText>
            )}
          </>
        ),
    },
    {
      title: t('customs_router.customs_dashboard.columns.supplier'),
      render: (ticket: Ticket) =>
        ticket.supplier && (
          <>
            {ticket.customsRead ? (
              ticket.supplier.businessName
            ) : (
              <StyledText>{ticket.supplier.businessName}</StyledText>
            )}
          </>
        ),
    },
    {
      title: t('customs_router.customs_dashboard.columns.date'),
      render: (ticket: Ticket) =>
        ticket.createAt && (
          <>
            {ticket.customsRead ? (
              moment(ticket.createAt).format(dateFormat)
            ) : (
              <StyledText>{moment(ticket.createAt).format(dateFormat)}</StyledText>
            )}
          </>
        ),
    },
    {
      title: t('customs_router.customs_dashboard.columns.carrier'),
      render: (ticket: Ticket) =>
        ticket.carrier && (
          <>
            {ticket.customsRead ? (
              ticket.carrier.businessName
            ) : (
              <StyledText>{ticket.carrier.businessName}</StyledText>
            )}
          </>
        ),
    },
    {
      title: t('customs_router.customs_dashboard.columns.car_equipment'),
      render: (ticket: Ticket) =>
        (ticket.railcarDocumentation?.carEquipment || ticket.truckDocumentation?.carEquipment) && (
          <>
            {ticket.customsRead ? (
              ticket.railcarDocumentation?.carEquipment || ticket.truckDocumentation?.carEquipment
            ) : (
              <StyledText>
                {ticket.railcarDocumentation?.carEquipment ||
                  ticket.truckDocumentation?.carEquipment}
              </StyledText>
            )}
          </>
        ),
    },
    {
      title: t('customs_router.customs_dashboard.columns.invoice_number'),
      render: (ticket: Ticket) =>
        ticket.invoice && (
          <>
            {ticket.customsRead ? (
              ticket.invoice.number
            ) : (
              <StyledText>{ticket.invoice.number}</StyledText>
            )}
          </>
        ),
    },
    {
      title: t('customs_router.customs_dashboard.columns.invoice_date'),
      render: (ticket: Ticket) =>
        ticket.invoice && (
          <>
            {ticket.customsRead ? (
              moment(ticket.invoice.date).format(dateFormat)
            ) : (
              <StyledText>{moment(ticket.invoice.date).format(dateFormat)}</StyledText>
            )}
          </>
        ),
    },
    {
      title: t('customs_router.customs_dashboard.columns.status'),
      render: (ticket: Ticket) =>
        ticket.status && (
          <>
            {ticket.customsRead ? (
              t(getStatusName(ticket.status.name))
            ) : (
              <StyledText>{t(getStatusName(ticket.status.name))}</StyledText>
            )}
          </>
        ),
    },
  ]

  const onActionClick = (id: number, value: boolean) => {
    changeTicket({ id, payload: { customsRead: !value } })
  }

  const actions = [
    {
      title: t('customs_router.customs_dashboard.columns.actions'),
      render: (ticket: Ticket) => (
        <IconContainer>
          {ticket.truckDocumentation && (
            <Link
              to={{
                pathname: `/dashboard/customs/truck-documentation/${ticket.id}`,
                state: { ticket },
              }}
              onClick={() =>
                ticket.customsRead ? null : onActionClick(ticket.id, ticket.customsRead)
              }
            >
              <EyeIcon fill={ticket.customsRead ? '#999999' : '#4157A1'} />
            </Link>
          )}
          {ticket.railcarDocumentation && (
            <Link
              to={{
                pathname: `/dashboard/customs/railcar-documentation/${ticket.id}`,
                state: { ticket },
              }}
              onClick={() =>
                ticket.customsRead ? null : onActionClick(ticket.id, ticket.customsRead)
              }
            >
              <EyeIcon fill={ticket.customsRead ? '#999999' : '#4157A1'} />
            </Link>
          )}
          {ticket.customsRead && (
            <UndoIcon
              width={20}
              height={20}
              fill="#A7A7A7"
              onClick={() => onActionClick(ticket.id, ticket.customsRead)}
            />
          )}
        </IconContainer>
      ),
    },
  ]

  return (
    <Table
      columns={[...columns, ...(showActions ? actions : [])]}
      loading={isLoading || changeTicketLoading}
      dataSource={dataSource}
      pagination={pagination}
      rowKey="id"
      scroll={{ y: 'calc(100vh - 410px)' }}
    />
  )
}

export { CustomsTable }
