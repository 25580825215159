import React, { useEffect, useState } from 'react'
import { Layout, Checkbox, Tooltip, Input, Select, Row, Col } from 'antd'
import AntdIcon from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { LogoExiros, LogoutIcon } from '@icons'
import { Filters, RoleEnum, SVGIcon } from '@types'
import { useLogout, useTickets } from '@hooks'
import { useUser, useFilters } from '@contexts'

import '../../firebase/firebase'

import {
  ContainerButtons,
  LogoContainer,
  StyledButton,
  StyledHeader,
  StyledSider,
  ContainerCheckbox,
  StyledCheckboxGroup,
  StyledContainer,
  UserColor,
  LogoutContainer,
  LoginInfoContainer,
  UserContainer,
  SearchContainer,
  LogoContainerMobile,
  UserContainerMobile,
} from './components'

// TODO: delete when all dashboards are refactored
interface DashboardLayoutProps {
  buttons?: {
    name: string
    primary: boolean
    svgColor: string
    icon: typeof AntdIcon | SVGIcon
    action: () => void
  }[]
  checkboxesGroup?: {
    name: string
    checkboxes: string[]
  }[]
  menu?: () => JSX.Element
  searchSelectOptions: { label: string; value: string }[]
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  checkboxesGroup,
  buttons,
  children,
  menu,
  searchSelectOptions,
}) => {
  const { t } = useTranslation()
  const { logout } = useLogout()
  const { filters, setFilters } = useFilters()
  const { state: user } = useUser()
  const { isLoading } = useTickets(filters)
  const { xs, sm, md } = useBreakpoint()

  const roleUser = user?.roles[0].name
  const [filterSelectSelected, setFilterSelectSelected] = useState(searchSelectOptions[0].value)
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (
      !filters?.ticketId &&
      !filters?.invoiceNumber &&
      !filters?.taInvoiceNumber &&
      !filters?.equipment
    )
      setInputValue(undefined)
  }, [filters])

  const handleSearch = (value: string) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      [filterSelectSelected]: value as string,
      carrierId: [],
      carrierMode: [],
      statusName: [],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
    }))
  }

  const handleChangeSelect = (value: string) => {
    setInputValue(undefined)
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
      carrierId: [],
      carrierMode: [],
      statusName: [],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
    }))

    setFilterSelectSelected(value)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    if (e.target.value === '')
      setFilters((prevState: Filters) => ({
        ...prevState,
        page: 1,
        ticketId: undefined,
        invoiceNumber: undefined,
        taInvoiceNumber: undefined,
        equipment: undefined,
      }))
  }

  return (
    <Layout>
      {xs || (sm && !md) ? (
        <>
          <Row>
            <Col span={4}>
              <LogoContainerMobile>
                <LogoExiros width={100} height={65} />
              </LogoContainerMobile>
            </Col>
            <Col span={15}>
              <UserContainerMobile>
                <LoginInfoContainer>
                  <h4>{user?.userDetail.name || <i>{t('globals.login.no_name')}</i>}</h4>
                  {user?.userDetail.carrier?.id && (
                    <p>
                      {user?.userDetail.carrier?.businessName || (
                        <i>{t('globals.login.no_email')}</i>
                      )}
                    </p>
                  )}
                  {user?.userDetail.supplier?.id && (
                    <p>
                      {user?.userDetail.supplier?.businessName || (
                        <i>{t('globals.login.no_email')}</i>
                      )}
                    </p>
                  )}
                  {user?.userDetail.customsAgent?.id && (
                    <p>
                      {user?.userDetail.customsAgent?.businessName || (
                        <i>{t('globals.login.no_email')}</i>
                      )}
                    </p>
                  )}
                  {user?.roles[0].name === RoleEnum.ADMIN && <p>Transamerica</p>}
                </LoginInfoContainer>
              </UserContainerMobile>
            </Col>
            <Col span={3}>
              <LogoutContainer>
                <Tooltip title={t('layout_dashboard.logout')}>
                  <LogoutIcon onClick={logout} />
                </Tooltip>
              </LogoutContainer>
            </Col>
            <UserColor role={roleUser} />
          </Row>
          <Row justify="center">
            <Col>
              <Input.Group compact>
                <Select
                  value={filterSelectSelected}
                  onChange={handleChangeSelect}
                  style={{ margin: '8px 0' }}
                >
                  {searchSelectOptions.map(({ label, value }) => (
                    <Select.Option value={value}>{label}</Select.Option>
                  ))}
                </Select>
                <Input.Search
                  placeholder="Search"
                  type="string"
                  onSearch={handleSearch}
                  onChange={handleInputChange}
                  loading={isLoading}
                  value={inputValue}
                />
              </Input.Group>
            </Col>
          </Row>
        </>
      ) : (
        <StyledHeader>
          <LogoContainer>
            <LogoExiros width={150} />
          </LogoContainer>
          <SearchContainer>
            <Input.Group compact>
              <Select
                value={filterSelectSelected}
                onChange={handleChangeSelect}
                style={{ width: 120 }}
              >
                {searchSelectOptions.map(({ label, value }) => (
                  <Select.Option key={label + value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
              <Input.Search
                style={{ width: '50%' }}
                placeholder="Search"
                type="string"
                onSearch={handleSearch}
                onChange={handleInputChange}
                loading={isLoading}
                value={inputValue}
              />
            </Input.Group>
          </SearchContainer>
          <UserContainer>
            <LoginInfoContainer>
              <h4>{user?.userDetail.name || <i>{t('globals.login.no_name')}</i>}</h4>
              {user?.userDetail.carrier?.id && (
                <p>
                  {user?.userDetail.carrier?.businessName || <i>{t('globals.login.no_email')}</i>}
                </p>
              )}
              {user?.userDetail.supplier?.id && (
                <p>
                  {user?.userDetail.supplier?.businessName || <i>{t('globals.login.no_email')}</i>}
                </p>
              )}
              {user?.userDetail.customsAgent?.id && (
                <p>
                  {user?.userDetail.customsAgent?.businessName || (
                    <i>{t('globals.login.no_email')}</i>
                  )}
                </p>
              )}
              {user?.roles[0].name === RoleEnum.ADMIN && <p>Transamerica</p>}
            </LoginInfoContainer>
            <LogoutContainer>
              <Tooltip title={t('layout_dashboard.logout')}>
                <LogoutIcon onClick={logout} />
              </Tooltip>
            </LogoutContainer>
          </UserContainer>
        </StyledHeader>
      )}
      {!xs || (!sm && md) ? <UserColor role={roleUser} /> : null}
      <Layout>
        <StyledSider>
          {menu && menu()}
          <>
            {buttons && (
              <ContainerButtons>
                {buttons.map(button => {
                  return (
                    <StyledButton
                      type={button.primary ? 'primary' : 'default'}
                      svgColor={button.svgColor}
                      block
                      onClick={button.action}
                      icon={<button.icon />}
                    >
                      {button.name}
                    </StyledButton>
                  )
                })}
              </ContainerButtons>
            )}

            {checkboxesGroup?.map(({ name, checkboxes }) => {
              return (
                <StyledCheckboxGroup>
                  <p>{name}</p>
                  {checkboxes.map(checkbox => {
                    return (
                      <ContainerCheckbox span={24}>
                        <Checkbox value={checkbox}>{checkbox}</Checkbox>
                      </ContainerCheckbox>
                    )
                  })}
                </StyledCheckboxGroup>
              )
            })}
          </>
        </StyledSider>
        <StyledContainer>{children}</StyledContainer>
      </Layout>
    </Layout>
  )
}

export { DashboardLayout }
