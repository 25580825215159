import React from 'react'

export type SVGIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>

export type User = {
  id: number
  userName: string
  roles: Role[]
  userDetail: UserDetail
}

export enum RoleEnum {
  ADMIN = 'ADMIN',
  ADMINRO = 'ADMINRO',
  SUPPLIER = 'SUPPLIER',
  CARRIER = 'CARRIER',
  CUSTOMS = 'CUSTOMS',
}

export enum WebTitleEnum {
  TITLE = 'MLO TA',
}

type Role = {
  id: number
  name: RoleEnum
  description: string
}

type UserDetail = {
  id: number
  name: string
  surname: string
  email: string
  supplier?: Supplier
  carrier?: Carrier
  customsAgent?: CustomsAgent
}

export type Carrier = {
  id: number
  tradeName: string
  businessName: string
  country?: string
  taxId: string
  email?: string
  cellphone?: string
  phone?: string
  address: string
  category: number
  enabledForAlgorithm: boolean
  location: Location
  mode: CarrierModeEnum
}

type Location = {
  type: string
  coordinates: [number, number]
}

export type Supplier = {
  id: number
  tradeName: string
  businessName: string
  country?: string
  taxId: string
  email?: string
  cellphone?: string
  phone?: string
  depots: Depot[]
  sapId: number
}

export type Depot = {
  id: number
  address: string
  description?: string
  location: Location
  city: string
  borderCrossing: BorderCrossing
}

export type Destination = {
  id: number
  name: string
  address: string
}

export enum TicketAssignment {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
}

export interface TruckDocumentation {
  bl: string[]
  id: number
  invoiceDate: string[]
  invoiceNumber: string
  invoiceAmount: number
  grossWeight: number
  tareWeight: number
  materialId: string
  materialDescription: string
  purchaseOrderId: string
  createAt: string
  photos: string[]
  salesInvoice: string[]
  weightCertificate: string[]
  radioactiveCertificate: string[]
  carEquipment: string
}
export interface RailcarDocumentation {
  waybill: string
  carEquipment: string
  id: number
  invoiceDate: string[]
  invoiceNumber: string
  invoiceAmount: number
  grossWeight: number
  tareWeight: number
  materialId: string
  materialDescription: string
  purchaseOrderId: string
  createAt: string
  photos: string[]
  comments: string
  salesInvoice: string[]
  weightCertificate: string[]
  BL: string[]
  radioactiveCertificate: string[]
}

export type Ticket = {
  id: number
  supplier: {
    id: number
    businessName: string
  }
  comments: string
  status: {
    name: StatusTicketEnum
  }
  createAt: Date
  carrier: Carrier
  withdrawalDate: string
  ticketAssignments: {
    id: number
    status: {
      id: number
      name: TicketAssignment
    }
    carrier: Carrier
  }[]
  destination: {
    name: string
    id: number
  }
  truckDocumentation?: TruckDocumentation
  railcarDocumentation?: RailcarDocumentation
  origin: Depot
  invoice?: Invoice
  customsRead: boolean
}

export type Invoice = {
  borderCrossing: BorderCrossing
  createAt: string
  date: string
  deleteAt?: string
  id: number
  invoiceFile: string
  number: string
  paymentNumber: string
  paymentDate: string
  paymentFile: string
  ticket: Ticket
  rpa: RPA
  sapValidationStatus: InvoiceCheckEnum
  sapValidationDate: Date
}

export type RPA = {
  id: number | '-'
  from: string
  to: string
  invoiceQty: number
}

export interface RPAFilters extends BaseFilters {
  rpaId?: string
  date?: Date
}

export type Contract = {
  id?: number
  description?: string
  price: number
  from?: Date
  to: Date
  carrier: Carrier
}

export enum StatusTicketEnum {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  INVOICED = 'INVOICED',
  TRANSIT = 'TRANSIT',
  DELIVERED = 'DELIVERED',
  AWAITING = 'AWAITING',
  RELEASED = 'RELEASED',
  SUPPLIER_INVOICED = 'SUPPLIER INVOICED',
  TA_INVOICED = 'TA INVOICED',
  MX_TRANSIT = 'MX TRANSIT',
  CANCELED = 'CANCELED',
}

export interface BaseFilters {
  limit?: number
  page?: number
}

export interface Filters extends BaseFilters {
  statusName: string[]
  supplierId?: number[]
  carrierId?: number[]
  destinationId?: number[]
  borderCrossingId?: number[]
  orderBy?: string
  ticketId?: string
  invoiceNumber?: string
  taInvoiceNumber?: string
  equipment?: string
  beforeDate?: Date
  carrierMode?: CarrierModeEnum[]
}

export interface ContractFilters extends BaseFilters {
  orderBy?: 'ASC' | 'DESC' | undefined
  contractStatus?: string[]
  carrierId?: number
}

export enum FilterSelectEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export enum CarrierModeEnum {
  RAILCAR = 'Railcar',
  TRUCK = 'Truck',
}

export interface CarrierFilters extends BaseFilters {
  carrierId?: number[]
  mode?: CarrierModeEnum
}

export interface SupplierFilters extends BaseFilters {
  supplierId?: number[]
}

export interface DepotFilters extends BaseFilters {
  depotId?: number[]
  city?: string
  supplierId?: number[]
}

export enum ConfigurationPropertiesEnum {
  ALLOCATION_FREQUENCY = 'ALLOCATION_FREQUENCY',
  DELETE_TICKETS_FREQUENCY = 'DELETE_TICKETS_FREQUENCY',
}

export enum InvoiceCheckEnum {
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type ConfigurationProperties = {
  id: number
  createAt: string
  key: string
  value: string
}

export type DeclineTicket = { reason: string }

export type CustomsReadTicket = { customsRead: boolean }

export type CustomsAgent = {
  id: number
  tradeName: string
  businessName: string
  country?: string
  email?: string
  phone?: string
  address: string
}

export type BorderCrossing = {
  id: number
  address: string
  description: string
  customsAgent: CustomsAgent
}

export enum NotificationEnum {
  NEW_TICKET = 'New ticket',
  CONFIRMED = 'Ticket confirmed',
  TRUCK = 'Truck loaded',
  INVOICED = 'Ticket invoiced',
  CHANGED_CUSTOMS = 'Changed Customs Agent',
}

export type PurchaseOrder = {
  documentNumberId: string
  position: string
  holding: string
  companyId: string
  companyDesc: string
  buyerDesc: string
  materialId: string
  materialDesc: string
  proposedDeliveryDate: string
  plantId: string
  plantDesc: string
  requestedQuantity: string
  entryQuantity: string
  preEntryQuantity: string
  inTransitQuantity: string
  availableQuantity: string
  um: string
  warehouseId: string
  finalDestinationId: string
  tolerance: string
  earlyDeliveryDays: string
  avaliableCheck: string
  earlyDeliveryDate: string
  supplierCode: string
}

export interface LocationState {
  from: {
    pathname: string
  }
}
