import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { notification } from 'antd'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import ReactGA from 'react-ga'

import { theme, GlobalStyles } from '@styles'
import { Layout, LoginWithToken, PrivateRoute } from '@components'
import { UserProvider, useUser } from '@contexts'
import { NotificationEnum, RoleEnum, LocationState } from '@types'
import { config } from '@config'
import { LOCAL_STORAGE_KEYS } from '@constants'
import { QUERY_KEYS } from '@api'
import { StringUtils } from '@utils'

import { SuppliersRouter } from './SuppliersRouter'
import { TplRouter } from './TplRouter'
import { AdminRouter } from './AdminRouter'
import { CustomsRouter } from './CustomsRouter'
import { LoginRouter } from './Login'
import { SetToken } from './Saml/Login'

import '../firebase/firebase'

const trackingId = config.measurementId
ReactGA.initialize(trackingId!)

notification.config({
  placement: 'topRight',
  duration: 20,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  },
})

// Initialize Firebase
const messaging = getMessaging()

onMessage(messaging, payload => {
  switch (payload.data?.title) {
    case NotificationEnum.NEW_TICKET:
      queryClient.refetchQueries([QUERY_KEYS.TICKETS])
      return notification.success({
        message: `Trip ID ${payload.data?.ticketNumber}: ${payload.data?.title} created by ${payload.data?.supplierName}`,
      })
    case NotificationEnum.CONFIRMED:
      queryClient.refetchQueries([QUERY_KEYS.TICKETS])
      return notification.success({
        message: `Trip ID ${payload.data?.ticketNumber}: ${payload.data?.title} by ${payload.data?.carrierName}`,
      })
    case NotificationEnum.TRUCK:
      queryClient.refetchQueries([QUERY_KEYS.TICKETS])
      return notification.success({
        message: `Trip ID ${payload.data?.ticketNumber}: ${payload.data?.title} by ${payload.data?.supplierName}`,
      })
    case NotificationEnum.INVOICED:
      queryClient.resetQueries([QUERY_KEYS.TICKETS])
      queryClient.refetchQueries([QUERY_KEYS.TICKETS])
      return notification.success({
        message: `Trip ID ${payload.data?.ticketNumber}: ${payload.data?.title} by Transamerica`,
      })
    case NotificationEnum.CHANGED_CUSTOMS:
      queryClient.resetQueries([QUERY_KEYS.TICKETS])
      queryClient.refetchQueries([QUERY_KEYS.TICKETS])
      return notification.success({
        message: `Trip ID ${payload.data?.ticketNumber}: ${payload.data?.title} by Transamerica`,
      })
    default:
      return notification.success({ message: 'Default message (not specified)' })
  }
})

getToken(messaging, {
  vapidKey: config.vapidKey,
})
  .then(token => {
    if (token) {
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.FIREBASE, token)
    }
  })
  .catch(() => {
    notification.success({
      message: 'To receive push notifications, give permission to your device',
    })
    // ...
  })

const DashboardRouter = () => {
  const { url } = useRouteMatch()
  const { state: user } = useUser()
  const location = useLocation<LocationState>()

  const currentPath = location.pathname || '/'
  const userRoles = user?.roles.map(role => role.name)

  const tripId = StringUtils.extractTrailingNumber(currentPath)
  // console.log('Dashboard router tripId:', tripId)

  // Handle redirection based on user role and current path
  const shouldRedirectToPredoc =
    currentPath.includes('ta-invoice') && userRoles?.includes(RoleEnum.CUSTOMS)
  const shouldRedirectToInvoice =
    currentPath.includes('-documentation/') && userRoles?.includes(RoleEnum.ADMIN)

  if (shouldRedirectToPredoc) {
    // console.log('shouldRedirectToPredoc', shouldRedirectToPredoc)
    location.pathname = currentPath.replace('admin/ta-invoice', 'customs/predoc')
  } else if (shouldRedirectToInvoice && tripId) {
    // console.log('shouldRedirectToInvoice', shouldRedirectToInvoice)
    location.pathname = `/dashboard/admin/ta-invoice/${tripId}`
  }
  // console.log('location.pathname: ', location.pathname)

  return (
    <Switch>
      <PrivateRoute
        path={`${url}/suppliers`}
        component={SuppliersRouter}
        allowedRoles={[RoleEnum.SUPPLIER]}
      />
      <PrivateRoute
        path={`${url}/carrier`}
        component={TplRouter}
        allowedRoles={[RoleEnum.CARRIER]}
      />
      <PrivateRoute
        path={`${url}/admin`}
        component={AdminRouter}
        allowedRoles={[RoleEnum.ADMIN, RoleEnum.ADMINRO]}
      />
      <PrivateRoute
        path={`${url}/customs`}
        component={CustomsRouter}
        allowedRoles={[RoleEnum.CUSTOMS]}
      />
    </Switch>
  )
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <QueryClientProvider client={queryClient}>
          <Layout>
            <UserProvider>
              <LoginWithToken>
                <Switch>
                  <Route path="/login" component={LoginRouter} />
                  <Route path="/dashboard" component={DashboardRouter} />
                  <Route path="/token" component={SetToken} />
                  <Redirect to="/login" />
                </Switch>
              </LoginWithToken>
            </UserProvider>
          </Layout>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  )
}

export { App }
