import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { TruckDocumentationApi } from './api'

const onError = () => {
  notification.error({
    message: 'Error updating truck',
  })
}

const useUpdateTruckDocumentation = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation(TruckDocumentationApi.updateTruckDocumentation, {
    onSuccess: data => {
      notification.success({
        message: `Truck ID: ${data.truckDocumentation.id} ${t(
          'ta_invoice.add_truck_documentation.title_and_divider.new_railcar.update',
        )}`,
      })
      history.push('/dashboard/suppliers')
    },
    onError,
  })

  return {
    updateTruckDocumentation: mutate,
    isLoading,
  }
}

export { useUpdateTruckDocumentation }
