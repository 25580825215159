import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { DashboardLayout } from '@components'
import { FiltersProvider } from '@contexts'

import { CustomsDashboard } from './CustomsDashboard'
import { SupplierMenu } from './CustomsMenu'
import PreDoc from './components/PreDoc'

const selectOptions = [
  { value: 'ticketId', label: 'Trip ID' },
  { value: 'taInvoiceNumber', label: 'TA Invoice #' },
  { value: 'equipment', label: 'Equipment ID' },
]

const CustomsRouter = () => {
  const { url } = useRouteMatch()

  return (
    <FiltersProvider>
      <Switch>
        <DashboardLayout menu={SupplierMenu} searchSelectOptions={selectOptions}>
          <Route exact path={url} component={CustomsDashboard} />
          <Route exact path={`${url}/predoc/:id`} component={PreDoc} />
          <Route exact path={`${url}/truck-documentation/:id`} component={PreDoc} />
          <Route exact path={`${url}/railcar-documentation/:id`} component={PreDoc} />
        </DashboardLayout>
      </Switch>
    </FiltersProvider>
  )
}

export { CustomsRouter }
