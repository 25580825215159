import { api } from '@api'
import { Ticket } from '@types'

import { UpdateForm } from '../../validations'

interface CreateTruckDocumentationResponse {
  truckDocumentation: Ticket
}

const updateTruckDocumentation = async (payload: UpdateForm) => {
  const {
    salesInvoice,
    weightCertificate,
    BL,
    radioactiveCertificate,
    photos,
    truckDocumentationId,
    ...restOfValues
  } = payload

  const truckDocumentationPayload = new FormData()

  if (salesInvoice) {
    truckDocumentationPayload.append('salesInvoice', salesInvoice as Blob)
  }
  if (weightCertificate) {
    truckDocumentationPayload.append('weightCertificate', weightCertificate as Blob)
  }
  if (BL) {
    truckDocumentationPayload.append('BL', BL as Blob)
  }
  if (radioactiveCertificate) {
    truckDocumentationPayload.append('radioactiveCertificate', radioactiveCertificate as Blob)
  }

  photos?.forEach(photo => {
    truckDocumentationPayload.append('photos', photo as Blob)
  })

  truckDocumentationPayload.append('truckDocumentation', JSON.stringify(restOfValues))

  const response = await api.put<CreateTruckDocumentationResponse>(
    `/trucks-documentation/${truckDocumentationId}`,
    truckDocumentationPayload,
  )

  return response.data
}

export const TruckDocumentationApi = { updateTruckDocumentation }
