import { useRouteMatch, Route, Redirect } from 'react-router-dom'

import { DashboardLayout } from '@components'
import {
  FiltersProvider,
  ContractFiltersProvider,
  CarrierFiltersProvider,
  SupplierFiltersProvider,
  DepotFiltersProvider,
  RPAFiltersProvider,
} from '@contexts'

import { Delivered } from './Requests/Delivered'
import { Contracts } from './Contracts'
import TAPreInvoice from './TAPreInvoice'
import { AdminMenu } from './AdminMenu'
import { Categories } from './Categories'
import { SuppliersAbm } from './SuppliersAbm'
import { DepotsAbm } from './DepotsAbm'
import { Settings } from './Settings'
import { ServiceLevel } from './ServiceLevel'
import { Accounting } from './Accounting'
import { Ongoing } from './Requests/Ongoing'

const selectOptions = [
  { value: 'ticketId', label: 'Trip ID' },
  { value: 'invoiceNumber', label: 'Invoice #' },
  { value: 'taInvoiceNumber', label: 'TA Invoice' },
  { value: 'equipment', label: 'Equipment ID' },
]

const AdminRouter = () => {
  const { url } = useRouteMatch()
  return (
    <FiltersProvider>
      <DashboardLayout menu={AdminMenu} searchSelectOptions={selectOptions}>
        <ContractFiltersProvider>
          <Route path={`${url}/contracts`} component={Contracts} />
        </ContractFiltersProvider>
        <Route path={`${url}/service-level`} component={ServiceLevel} />
        <RPAFiltersProvider>
          <Route path={`${url}/accounting`} component={Accounting} />
        </RPAFiltersProvider>
        <Route path={`${url}/ta-invoice/:id`} component={TAPreInvoice} />
        <CarrierFiltersProvider>
          <Route path={`${url}/categories`} component={Categories} />
        </CarrierFiltersProvider>
        <SupplierFiltersProvider>
          <Route path={`${url}/suppliersabm`} component={SuppliersAbm} />
        </SupplierFiltersProvider>
        <DepotFiltersProvider>
          <Route path={`${url}/depotsabm`} component={DepotsAbm} />
        </DepotFiltersProvider>
        <Route path={`${url}/settings`} component={Settings} />
        <Route exact path={`${url}`}>
          <Redirect to={`${url}/ongoing`} />
        </Route>
        <Route exact path={`${url}/ongoing`} component={Ongoing} />
        <Route path={`${url}/delivered`} component={Delivered} />
      </DashboardLayout>
    </FiltersProvider>
  )
}

export { AdminRouter }
