import { useRouteMatch, Route } from 'react-router-dom'

import { DashboardLayout } from '@components'
import { FiltersProvider, TicketNotifProvider } from '@contexts'

import { CarrierMenu } from './CarrierMenu'
import { TplDashboard } from './TplDashboard'

const selectOptions = [
  { value: 'ticketId', label: 'Trip ID' },
  { value: 'invoiceNumber', label: 'Invoice #' },
  { value: 'taInvoiceNumber', label: 'TA Invoice' },
  { value: 'equipment', label: 'Equipment ID' },
]

const TplRouter = () => {
  const { url } = useRouteMatch()

  return (
    <FiltersProvider>
      <DashboardLayout menu={CarrierMenu} searchSelectOptions={selectOptions}>
        <TicketNotifProvider>
          <Route exact path={url} component={TplDashboard} />
        </TicketNotifProvider>
      </DashboardLayout>
    </FiltersProvider>
  )
}

export { TplRouter }
