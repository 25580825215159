import { Col, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { SelectValue } from 'antd/lib/select'

import { useBorderCrossings, usePagination, useTickets } from '@hooks'
import { Filters, StatusTicketEnum, CarrierModeEnum } from '@types'
import { useFilters } from '@contexts'
import { RailcarIcon, TruckIcon } from '@icons'

import { Container, StyledCol, StyledSelect, StyledSpin, StyledRow } from './components'
import { CustomsTable } from '../components/CustomsTable'

const CustomsDashboard = () => {
  const { t } = useTranslation()
  const { filters, setFilters } = useFilters()
  const { tickets, isLoading } = useTickets(filters)
  const { userBordersOptions, userBordersLoading } = useBorderCrossings()
  const pagination = usePagination({ filters, setFilters }, tickets?.total)

  const filterOptions = [
    {
      label: `${t('customs_router.filter_options.awaiting')}`,
      value: StatusTicketEnum.TA_INVOICED,
    },
    {
      label: `${t('customs_router.filter_options.released')}`,
      value: StatusTicketEnum.MX_TRANSIT,
    },
  ]

  const orderByOptions = [
    {
      label: `${t('admin_router.filter_options.order_by.ascending')}`,
      value: 'ASC',
    },
    {
      label: `${t('admin_router.filter_options.order_by.descending')}`,
      value: 'DESC',
    },
  ]

  const carrierModeOptions = [
    {
      label: (
        <>
          <RailcarIcon height={15} width={15} />{' '}
          {t('suppliers_router.filter_options.carrier.railcar')}
        </>
      ),
      value: CarrierModeEnum.RAILCAR,
    },
    {
      label: (
        <>
          <TruckIcon height={15} width={15} /> {t('suppliers_router.filter_options.carrier.truck')}
        </>
      ),
      value: CarrierModeEnum.TRUCK,
    },
  ]

  const handleCarrierModeFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierMode: [...(value as CarrierModeEnum[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const handleStatusFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      statusName: [...(value as string[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const handleOrderByFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      orderBy: value as string,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const handleBorderFilterChange = (value: SelectValue) => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      borderCrossingId: [...(value as number[])],
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const handleCleanFilters = () => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierId: [],
      carrierMode: [],
      statusName: [],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  return (
    <Container>
      <StyledRow gutter={[10, 10]}>
        <Col span={3}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('suppliers_router.filter_options.carrier.mode')}
            options={carrierModeOptions}
            onChange={handleCarrierModeFilterChange}
            value={filters?.carrierMode}
            maxTagCount="responsive"
          />
        </Col>
        <Col span={6}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('admin_router.filter_options.status.title')}
            options={filterOptions}
            onChange={handleStatusFilterChange}
            value={filters?.statusName}
            maxTagCount="responsive"
          />
        </Col>
        <Col xs={8} sm={6} md={5}>
          <StyledSelect
            allowClear
            placeholder={t('admin_router.filter_options.order_by.title')}
            options={orderByOptions}
            onChange={handleOrderByFilterChange}
            value={filters?.orderBy}
          />
        </Col>
        <Col xs={8} sm={6} md={5}>
          <StyledSelect
            mode="multiple"
            allowClear
            placeholder={t('Border Crossing')}
            options={userBordersOptions}
            loading={userBordersLoading}
            onChange={handleBorderFilterChange}
            value={filters?.borderCrossingId}
          />
        </Col>
        <StyledCol>
          <Button onClick={handleCleanFilters}>Clean filters</Button>
        </StyledCol>
      </StyledRow>
      {tickets ? (
        <CustomsTable dataSource={tickets?.tickets} isLoading={isLoading} pagination={pagination} />
      ) : (
        <StyledSpin />
      )}
    </Container>
  )
}

export { CustomsDashboard }
