import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useTranslation } from 'react-i18next'

import { HomeIcon, TruckIcon, NewTicketIcon, DeleteTicketIcon, RailcarIcon } from '@icons'
import { Filters } from '@types'
import { useFilters } from '@contexts'

import { StyledMenu, StyledMenuItem } from './components'
import { useHandleDeleteTicketModal, useHandleNewTicketModal } from '../SuppliersDashboard/contexts'

const SupplierMenu: () => JSX.Element = () => {
  const { pathname } = useLocation()
  const { url } = useRouteMatch()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { xs, sm, md } = useBreakpoint()
  const { setFilters } = useFilters()

  const handleCleanFilters = () => {
    setFilters((prevState: Filters) => ({
      ...prevState,
      page: 1,
      carrierId: [],
      carrierMode: [],
      statusName: [],
      supplierId: [],
      destinationId: [],
      borderCrossingId: [],
      orderBy: undefined,
      ticketId: undefined,
      invoiceNumber: undefined,
      taInvoiceNumber: undefined,
      equipment: undefined,
    }))
  }

  const onHomeClick = () => {
    push('/dashboard/suppliers')
    handleCleanFilters()
  }

  const { setIsTicketModalOpen } = useHandleNewTicketModal()
  const { setIsDeleteTicketModalOpen } = useHandleDeleteTicketModal()

  return (
    <StyledMenu selectedKeys={[pathname]}>
      {(xs || sm) && !md && (
        <StyledMenuItem key="newticket" onClick={() => setIsTicketModalOpen(true)}>
          <NewTicketIcon width={35} height={30} />
          <span>{t('suppliers_router.menu.new_ticket')}</span>
        </StyledMenuItem>
      )}

      <StyledMenuItem key={`${url}/suppliers-dashboard`} onClick={() => onHomeClick()}>
        <HomeIcon width={!((xs || sm) && md) ? 35 : 18} height={!((xs || sm) && md) ? 30 : 15} />
        <span>
          {xs || sm ? t('suppliers_router.menu.home') : t('suppliers_router.menu.my_requests')}
        </span>
      </StyledMenuItem>
      {!((xs || sm) && !md) && (
        <StyledMenuItem
          key={`${url}/suppliers-dashboard/truck-documentation`}
          onClick={() => push('/dashboard/suppliers/truck-documentation')}
        >
          <TruckIcon width={18} height={15} />
          <span>{t('suppliers_router.menu.load_truck')}</span>
        </StyledMenuItem>
      )}
      {!((xs || sm) && !md) && (
        <StyledMenuItem
          key={`${url}/suppliers-dashboard/railcar-documentation`}
          onClick={() => push('/dashboard/suppliers/railcar-documentation')}
        >
          <RailcarIcon width={18} height={15} />
          <span>{t('suppliers_router.menu.load_railcar')}</span>
        </StyledMenuItem>
      )}

      {(xs || sm) && !md && (
        <StyledMenuItem key="delete ticket" onClick={() => setIsDeleteTicketModalOpen(true)}>
          <DeleteTicketIcon width={35} height={30} />
          <span>{t('suppliers_router.menu.delete_ticket')}</span>
        </StyledMenuItem>
      )}
    </StyledMenu>
  )
}

export { SupplierMenu }
